import React from 'react';

function ScoreRequests() {
  return (
    <div>
      Nothing here yet!
    </div>
  );
}

export default ScoreRequests;
