import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@makeably/creativex-design-system';
import SearchableTable from 'components/organisms/SearchableTable';
import { setItemElement } from 'utilities/itemElement';
import {
  accountSetupFacebookAdsPath,
  editSettingsLinkedPlatformAccountPath,
  relinkInstructionsAccountSetupDV360AdPath,
  relinkInstructionsAccountSetupInstagramPostPath,
  relinkInstructionsAccountSetupSnapchatAdPath,
  requestRelinkAccountSetupAdwordPath,
  updateStateSettingsLinkedPlatformAccountPath,
  relinkAccountSetupPinterestPath,
} from 'utilities/routes';
import { saveToLocalStorage } from 'utilities/storage';

const accountProps = PropTypes.shape({
  accountClass: PropTypes.string,
  accountId: PropTypes.string,
  accountType: PropTypes.string,
  brand: PropTypes.string,
  connection: PropTypes.oneOf(['oauth', 'partner']),
  dateLinked: PropTypes.string,
  market: PropTypes.string,
  orgName: PropTypes.string,
  owner: PropTypes.string,
  partner: PropTypes.string,
  platformLookup: PropTypes.string,
  recordClass: PropTypes.string,
  recordId: PropTypes.number,
  status: PropTypes.string,
});
export const propTypes = {
  accountsByStatus: PropTypes.objectOf(PropTypes.arrayOf(accountProps)).isRequired,
  afterConnection: PropTypes.bool.isRequired,
  dv360RelinkUrl: PropTypes.string.isRequired,
  fbAdAccountPageCountLookup: PropTypes.objectOf(PropTypes.number).isRequired,
  platformLookup: PropTypes.objectOf(PropTypes.string).isRequired,
};

const filterKeys = ['brand', 'market', 'platform', 'partner', 'owner'];
const tabs = ['lapsed', 'active', 'inactive', 'all'];

export const pageStorageKey = 'adAccountFilter';

const OAUTH_CONNECTION_TYPE = 'oauth';

function statusFormatter(value) {
  const statusLabels = {
    active: 'Active',
    all: 'All',
    inactive: 'Inactive',
    lapsed: 'Needs Reconnecting',
  };

  return statusLabels[value];
}

function getHeaders(key) {
  const allHeaders = [
    {
      label: 'Platform',
      key: 'platform',
    },
    {
      label: 'Account ID',
      key: 'accountId',
    },
    {
      label: 'Brand',
      key: 'brand',
    },
    {
      label: 'Market',
      key: 'market',
    },
    {
      label: 'Associated Brand Pages',
      key: 'associatedCount',
      tooltip: 'The number of Facebook Brand Pages associated with each account.',
    },
    {
      label: 'Partner',
      key: 'partner',
    },
    {
      label: 'Owner',
      key: 'owner',
    },
    {
      label: 'Date Linked',
      key: 'dateLinked',
    },
    {
      label: 'Status',
      key: 'status',
      filter: 'all',
    },
    {
      label: 'Actions',
      key: 'actions',
    },
  ];

  return allHeaders.filter((header) => (header.filter ? header.filter === key : true));
}

function confirmationMessage(item, action) {
  const {
    accountId,
    orgName,
    recordName,
  } = item;

  return `Are you sure you want to ${action} ${recordName} (${accountId}) for ${orgName}?`;
}

function relinkUrl(item, dv360RelinkUrl) {
  let url = null;
  const {
    accountClass,
    connection,
    recordClass,
    recordId,
  } = item;

  switch (recordClass) {
    case 'InstagramBusinessAccount':
      url = relinkInstructionsAccountSetupInstagramPostPath(recordId);
      break;
    case 'FacebookAdAccount':
      if (connection === OAUTH_CONNECTION_TYPE) {
        url = '/auth/meta_ads_reconnection';
      } else {
        // Set to partner flow or both flows but account auth is missing
        url = accountSetupFacebookAdsPath();
      }
      break;
    default:
      // AdAccount cases
      switch (accountClass) {
        case 'AdWordsAccount':
          url = requestRelinkAccountSetupAdwordPath(recordId);
          break;
        case 'SnapchatAdAccount':
          url = relinkInstructionsAccountSetupSnapchatAdPath(recordId);
          break;
        case 'DV360Account':
          if (connection === OAUTH_CONNECTION_TYPE) {
            url = relinkInstructionsAccountSetupDV360AdPath(recordId);
          } else {
            url = dv360RelinkUrl;
          }
          break;
        case 'PinterestAccount':
          url = relinkAccountSetupPinterestPath(recordId);
          break;
        default:
          break;
      }
  }

  return {
    label: 'Activate',
    url,
  };
}

function activateUrl(item, includeConfirmation = false) {
  const {
    facebookAdAccountId,
    recordId,
    recordClass,
  } = item;

  let message;

  if (includeConfirmation) {
    message = confirmationMessage(item, 'activate');
  }

  return {
    confirmationMessage: message,
    label: 'Activate',
    method: 'post',
    url: updateStateSettingsLinkedPlatformAccountPath(
      recordId,
      {
        account_class: recordClass,
        facebook_ad_account_id: facebookAdAccountId,
        state: 'active',
      },
    ),
  };
}

function deactivateUrl(item) {
  const {
    facebookAdAccountId,
    recordId,
    recordClass,
  } = item;

  return {
    confirmationMessage: confirmationMessage(item, 'deactivate'),
    label: 'Deactivate',
    method: 'post',
    url: updateStateSettingsLinkedPlatformAccountPath(
      recordId,
      {
        account_class: recordClass,
        facebook_ad_account_id: facebookAdAccountId,
        state: 'inactive',
      },
    ),
    warning: true,
  };
}

function generateItemAssociatedCount({ recordClass, recordId }, fbAdAccountPageCountLookup) {
  if (recordClass === 'FacebookAdAccount') {
    const count = fbAdAccountPageCountLookup[recordId] || 0;
    return {
      text: count.toString(),
      value: count,
    };
  }
  return {
    text: 'N/A',
    value: -1,
  };
}

function generateAccountUrl({ recordClass, recordId }) {
  const params = { account_class: recordClass };
  return editSettingsLinkedPlatformAccountPath(recordId, params);
}

function generateAccountId(item) {
  return {
    display: { url: generateAccountUrl(item) },
    value: item.accountId,
  };
}

function generateItemActions(item, dv360RelinkUrl) {
  let display;
  const { status } = item;

  if (status === 'lapsed') {
    display = {
      urls: [
        relinkUrl(item, dv360RelinkUrl),
        deactivateUrl(item),
      ],
    };
  } else if (status === 'inactive') {
    display = { urls: [activateUrl(item, true)] };
  } else {
    // Active status
    display = { urls: [deactivateUrl(item)] };
  }

  return {
    display,
    value: '',
  };
}

function formatAccountsByStatus(
  actsByStatus,
  dv360RelinkUrl,
  fbAdAccountPageCountLookup,
  platformLookup,
) {
  const statuses = Object.keys(actsByStatus);
  const accountsByStatus = {};

  statuses.forEach((status) => {
    const current = actsByStatus[status];

    accountsByStatus[status] = current.map((item) => setItemElement({
      accountId: generateAccountId(item),
      actions: generateItemActions(item, dv360RelinkUrl),
      associatedCount: generateItemAssociatedCount(item, fbAdAccountPageCountLookup),
      brand: { value: item.brand },
      dateLinked: {
        value: item.dateLinked,
        format: 'date',
      },
      id: { value: `${item.accountType}:${item.recordId}` },
      market: { value: item.market },
      owner: { value: item.owner },
      partner: { value: item.partner },
      platform: { value: platformLookup[item.platformLookup] },
      status: {
        text: statusFormatter(item.status),
        value: item.status,
      },
    }));
  });

  return accountsByStatus;
}

function AdAccounts({
  accountsByStatus,
  afterConnection,
  dv360RelinkUrl,
  fbAdAccountPageCountLookup,
  platformLookup,
}) {
  const formattedAccountsByStatus = formatAccountsByStatus(
    accountsByStatus,
    dv360RelinkUrl,
    fbAdAccountPageCountLookup,
    platformLookup,
  );

  const tabDetails = tabs.map((key) => {
    const icon = (key === 'lapsed') ? <Icon color="red" name="exclamationCircle" /> : undefined;

    return {
      key,
      name: statusFormatter(key),
      icon,
    };
  });

  if (afterConnection) {
    const defaultState = {
      sort: {
        asc: false,
        key: 'dateLinked',
      },
      tab: 'active',
    };

    saveToLocalStorage(defaultState, pageStorageKey);
  }

  return (
    <SearchableTable
      csvFileName="accounts"
      filterKeys={filterKeys}
      getHeaders={getHeaders}
      groupedItems={formattedAccountsByStatus}
      pageStorageKey={pageStorageKey}
      tabDetails={tabDetails}
    />
  );
}

AdAccounts.propTypes = propTypes;

export default AdAccounts;
