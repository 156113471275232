import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Button,
  Card,
  Divider,
  Dropdown,
  Search,
  useLocalStorage,
} from '@makeably/creativex-design-system';
import {
  emptyState,
  filterProps,
  getNullableDimension,
  optionArrayProps,
  renderDeleteButton,
} from 'components/internal/shared';
import FilterTags from 'components/molecules/FilterTags';
import ItemsFilter from 'components/molecules/ItemsFilter';
import ItemsTable from 'components/molecules/ItemsTable';
import {
  addErrorToast,
  addToast,
} from 'components/organisms/Toasts';
import { findObjectByValue } from 'utilities/array';
import { saveItemsCsvFile } from 'utilities/file';
import { saveFilterQuery } from 'utilities/filtering';
import { getItemSortBy } from 'utilities/item';
import {
  filterItems,
  getValueOptions,
} from 'utilities/itemFilter';
import { searchItems } from 'utilities/itemSearch';
import { destroy } from 'utilities/requests';
import {
  internalDV360AdAccountPath,
  newInternalDV360AdAccountPath,
  newInternalDV360CampaignPath,
} from 'utilities/routes';
import {
  titleize,
  toDate,
} from 'utilities/string';
import {
  getPage,
  getParams,
  redirectWithParam,
  setParam,
} from 'utilities/url';

const accountProps = PropTypes.shape({
  advertiserId: PropTypes.string.isRequired,
  campaignIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  canDelete: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  lastUpdated: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  brand: PropTypes.string,
  market: PropTypes.string,
});

const propTypes = {
  adAccounts: PropTypes.arrayOf(accountProps).isRequired,
  companyOptions: optionArrayProps.isRequired,
  initialFilterSelections: filterProps,
  selectedCompanyId: PropTypes.number,
};

const defaultProps = {
  initialFilterSelections: {},
  selectedCompanyId: undefined,
};

const csvHeaders = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'advertiserId',
    label: 'Advertiser ID',
  },
  {
    key: 'level',
    label: 'Level',
  },
  {
    key: 'campaignIds',
    label: 'Campaigns',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    key: 'lastUpdated',
    label: 'Last Updated',
  },
  {
    key: 'state',
    label: 'State',
  },
];

const headers = [
  ...csvHeaders,
  {
    key: 'delete',
    label: '',
    sortable: false,
  },
];

const filterDimensions = [
  {
    value: 'brand',
    label: 'Brand',
  },
  {
    value: 'level',
    label: 'Level',
  },
  {
    value: 'market',
    label: 'Market',
  },
  {
    value: 'state',
    label: 'State',
  },
];

function getItems(adAccounts, onDelete, deletingId) {
  return adAccounts.map(({
    advertiserId,
    brand,
    campaignIds,
    canDelete,
    id,
    lastUpdated,
    level,
    market,
    state,
  }) => ({
    advertiserId: { value: advertiserId },
    brand: getNullableDimension(brand),
    campaignIds: {
      element: <a href={internalDV360AdAccountPath(id)}>{ campaignIds.length }</a>,
      value: campaignIds.join(';'),
    },
    delete: {
      element: renderDeleteButton(() => onDelete(id), !canDelete, id === deletingId),
      value: id,
    },
    id: {
      element: <a href={internalDV360AdAccountPath(id)}>{ id }</a>,
      value: id,
    },
    lastUpdated: {
      label: toDate(new Date(lastUpdated)),
      value: lastUpdated,
    },
    level: { value: level },
    market: getNullableDimension(market),
    state: {
      label: titleize(state),
      value: state,
    },
  }));
}

function Dv360AdAccounts({
  adAccounts,
  companyOptions,
  initialFilterSelections,
  selectedCompanyId,
}) {
  const params = getParams(window);
  const [lastCompanyId, setLastCompanyId] = useLocalStorage('cxIntCompanyId', null);
  const [selectedCompany, setSelectedCompany] = useState(
    findObjectByValue(companyOptions, selectedCompanyId),
  );
  const [items, setItems] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [filterSelections, setFilterSelections] = useState(initialFilterSelections);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({
    key: 'lastUpdated',
    asc: false,
  });
  const [page, setPage] = useState(getPage(params));
  const [deletingId, setDeletingId] = useState(null);

  const handleCompanyChange = (option) => {
    setSelectedCompany(option);
    setLastCompanyId(option?.value);
    redirectWithParam('company_id', option.value, params, window);
  };

  const handleDelete = async (id) => {
    setDeletingId(id);

    const { isError } = await destroy(internalDV360AdAccountPath(id));

    if (!isError) {
      addToast('DV360 ad account removed');
    } else {
      addErrorToast('Could not remove DV360 ad account. Please try again later');
    }
    window.location.reload();
  };

  useEffect(() => {
    if (!selectedCompanyId && lastCompanyId) {
      const found = findObjectByValue(companyOptions, lastCompanyId);

      if (found) {
        handleCompanyChange(found);
      }
    }
  }, [selectedCompanyId, companyOptions, lastCompanyId]);

  useEffect(() => {
    const allItems = getItems(adAccounts, handleDelete, deletingId);
    const options = getValueOptions(filterDimensions, allItems);

    setItems(allItems);
    setFilterOptions(options);
  }, [adAccounts, deletingId]);

  const filteredItems = useMemo(() => (
    filterItems(items, filterSelections)
  ), [items, filterSelections]);

  const searchedItems = useMemo(() => (
    searchItems(filteredItems, search, ['advertiserId'])
  ), [filteredItems, search]);

  const sortedItems = useMemo(() => {
    const byKeyDir = getItemSortBy(sort.key, sort.asc);
    return searchedItems.slice().sort(byKeyDir);
  }, [searchedItems, sort]);

  const handleFilterSelect = async (value) => {
    setPage(1);
    setFilterSelections(value);

    if (Object.keys(value).length > 0) {
      const uuid = await saveFilterQuery(value);
      setParam('filter_uuid', uuid, params, window);
    } else {
      setParam('filter_uuid', '', params, window);
    }
  };

  return (
    <Card className="u-flexColumn u-gap-24">
      <Dropdown
        label="Company"
        menuProps={{ size: 'medium' }}
        options={companyOptions}
        selected={selectedCompany}
        size="medium"
        onChange={handleCompanyChange}
      />
      <Divider />
      <div className="u-flexRow u-justifyEnd u-gap-16">
        <AddNewButton
          label="Add Campaigns"
          url={newInternalDV360CampaignPath({ company_id: selectedCompany?.value })}
        />
        <AddNewButton
          label="Connect Ad Accounts"
          url={newInternalDV360AdAccountPath({ company_id: selectedCompany?.value })}
        />
      </div>
      <div className="u-flexRow u-justifyBetween">
        <div className="u-flexRow u-gap-16">
          <Search
            placeholder="Search by Advertiser ID"
            value={search}
            onChange={setSearch}
          />
          <div className="u-flexRow u-alignCenter u-gap-8">
            <ItemsFilter
              dimensions={filterDimensions}
              isOpen={filterOpen}
              options={filterOptions}
              selections={filterSelections}
              onClose={() => setFilterOpen(false)}
              onOpen={() => setFilterOpen(true)}
              onSelect={handleFilterSelect}
            />
            <FilterTags
              dimensions={filterDimensions}
              selections={filterSelections}
              onClick={() => setFilterOpen(true)}
              onRemove={handleFilterSelect}
            />
          </div>
        </div>
        <Button
          label="Download CSV"
          variant="secondary"
          onClick={() => saveItemsCsvFile('DV360AdAccounts', sortedItems, csvHeaders)}
        />
      </div>
      <ItemsTable
        className="u-scrollShadowRight"
        emptyTableContent={emptyState}
        headers={headers}
        items={sortedItems}
        page={page}
        sort={sort}
        onPageChange={setPage}
        onSortChange={setSort}
      />
    </Card>
  );
}

Dv360AdAccounts.propTypes = propTypes;
Dv360AdAccounts.defaultProps = defaultProps;

export default Dv360AdAccounts;
