import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  ActionButton,
  Card,
  CopyButton,
  Icon,
} from '@makeably/creativex-design-system';
import {
  emptyState,
  isNotDefined,
  getBoolDimension,
  getDateTimeDimension,
  getLinkDimension,
  NULL_VALUE,
} from 'components/internal/shared';
import {
  addErrorToast,
  addToast,
} from 'components/organisms/Toasts';
import { get } from 'utilities/requests';
import {
  internalErrorQueryPath,
  internalExplorerAdAccountPath,
  internalExplorerUserPath,
  runInternalErrorQueryPath,
} from 'utilities/routes';
import styles from './ErrorQuery.module.css';

export const queryProps = PropTypes.shape({
  autorun: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sql: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

export const resultProps = PropTypes.shape({
  ids: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  key: PropTypes.string.isRequired,
  lastRunAt: PropTypes.string.isRequired,
});

const propTypes = {
  query: queryProps.isRequired,
  result: resultProps,
};

const defaultProps = {
  result: undefined,
};

const explorerUrls = {
  AdAccount: internalExplorerAdAccountPath,
  User: internalExplorerUserPath,
};

function getResults(ids) {
  if (ids.length === 0) return { value: 0 };

  return {
    element: (
      <div className="u-flexRow u-alignCenter u-gap-8">
        { ids.length }
        <CopyButton
          text={JSON.stringify(ids)}
          onClick={() => addToast(`Copied ${ids.length} results`)}
        />
      </div>
    ),
    value: ids.length,
  };
}

function getRun(key, running, handleRun) {
  return {
    element: (
      <ActionButton
        active={running === key}
        disabled={Boolean(running)}
        label="Run"
        variant="secondary"
        onClick={() => handleRun(key)}
      />
    ),
    value: NULL_VALUE,
  };
}

function getStatus(ids) {
  if (isNotDefined(ids)) {
    return {
      element: <Icon color="grey" name="questionCircle" />,
      value: 0,
    };
  }

  if (ids.length === 0) {
    return {
      element: <Icon color="green" name="checkCircle" />,
      value: -1,
    };
  }

  return {
    element: <Icon color="red" name="xCircle" />,
    value: ids.length,
  };
}

export function getItem(query, results, handleRun, running) {
  const {
    autorun,
    key,
    name,
  } = query;
  const result = results[key] ?? {};
  const {
    ids,
    lastRunAt,
  } = result;

  return {
    autorun: getBoolDimension(autorun),
    id: { value: key },
    lastRun: getDateTimeDimension(lastRunAt),
    name: getLinkDimension(name, internalErrorQueryPath(key)),
    results: getResults(ids ?? []),
    run: getRun(key, running, handleRun),
    status: getStatus(ids),
  };
}

function renderId(id, type, isLast) {
  const url = explorerUrls[type]?.(id);

  if (!url) {
    return (
      <span key={id}>
        <span>{ id }</span>
        <span>{ isLast ? '' : ',' }</span>
      </span>
    );
  }

  return (
    <span key={id}>
      <a href={url} rel="noreferrer" target="_blank">{ id }</a>
      <span>{ isLast ? '' : ',' }</span>
    </span>
  );
}

function renderResult(result, type, isLast) {
  if (Array.isArray(result)) {
    return (
      <span key={result} className={styles.result}>
        <span>[</span>
        { result.map((id, idx) => renderResult(id, type, idx === result.length - 1)) }
        <span>], </span>
      </span>
    );
  }

  return renderId(result, type, isLast);
}

function renderResults(ids, type) {
  if (!ids) return emptyState;

  return (
    <div className={styles.results}>
      { ids.map((value, index) => renderResult(value, type, index === ids.length - 1)) }
    </div>
  );
}

function ErrorQuery({ query, result }) {
  const [results, setResults] = useState({ [query.key]: result });
  const [running, setRunning] = useState(null);
  const {
    autorun,
    description,
    key,
    name,
    sql,
    type,
  } = query;

  const handleRun = async () => {
    setRunning(key);
    const { data, isError } = await get(runInternalErrorQueryPath(key));

    if (isError) {
      addErrorToast('Could not run query. Please try again later');
    } else {
      setResults((last) => ({
        ...last,
        [key]: data,
      }));
    }
    setRunning(null);
  };

  const item = useMemo(() => (
    getItem(query, results, handleRun, running)
  ), [query, result, handleRun, running]);

  useEffect(() => {
    if (autorun && !results[key]) {
      handleRun();
    }
  }, [query, results]);

  return (
    <Card className="u-flexColumn u-gap-16">
      <div>
        <h4>{ name }</h4>
        <div className="u-marginTop-8">{ description }</div>
      </div>
      <div>
        <div className="u-flexRow u-alignCenter u-gap-8">
          <h6>Status:</h6>
          { item.status.element }
        </div>
        <div className="u-flexRow u-alignCenter u-gap-8">
          <h6>Last Run:</h6>
          { item.lastRun.label }
        </div>
        <div className="u-flexRow u-alignCenter u-gap-8">
          <h6>Autorun:</h6>
          <div>{ item.autorun.label }</div>
        </div>
        <div className="u-flexRow u-alignCenter u-gap-8">
          <h6>Result Type::</h6>
          { type }
        </div>
      </div>
      <div>
        { item.run.element }
      </div>
      <div>
        <div className="u-flexRow u-alignCenter u-gap-8 u-marginBottom-8">
          <h6>SQL Query:</h6>
          <CopyButton
            text={sql}
            onClick={() => addToast('Copied SQL Query')}
          />
        </div>
        <div className={styles.sql}>
          { sql }
        </div>
      </div>
      <div>
        <div className="u-flexRow u-alignCenter u-gap-8 u-marginBottom-8">
          <h6>Results:</h6>
          { item.results.element }
        </div>
        { renderResults(results[key]?.ids, type) }
      </div>
    </Card>
  );
}

ErrorQuery.propTypes = propTypes;
ErrorQuery.defaultProps = defaultProps;

export default ErrorQuery;
