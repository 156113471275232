import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Icon,
  MaxWidthText,
  Pagination,
  Table,
} from '@makeably/creativex-design-system';
import {
  getItemContent,
  itemProps,
} from 'utilities/item';
import { sanitizeString } from 'utilities/string';
import styles from 'components/molecules/ItemsTable.module.css';

export const headerProps = PropTypes.shape({
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  element: PropTypes.element,
  highlighted: PropTypes.bool,
  sortable: PropTypes.bool,
});
const propTypes = {
  headers: PropTypes.arrayOf(headerProps).isRequired,
  items: PropTypes.arrayOf(itemProps).isRequired,
  className: PropTypes.string,
  emptyTableContent: PropTypes.node,
  maxWidthSize: PropTypes.string,
  page: PropTypes.number,
  perPage: PropTypes.number,
  sort: PropTypes.shape({
    asc: PropTypes.bool,
    key: PropTypes.string,
  }),
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
};
const defaultProps = {
  className: undefined,
  emptyTableContent: null,
  maxWidthSize: 'medium',
  onPageChange: undefined,
  onSortChange: undefined,
  page: 1,
  perPage: undefined,
  sort: undefined,
};

function getHeaderIcon(key, sort) {
  if (key !== sort?.key) {
    return 'blank';
  }
  return sort?.asc ? 'smallArrowUp' : 'smallArrowDown';
}

function getSortLabel(header, sort) {
  if (sort && header.key === sort.key) {
    if (sort.asc) {
      return 'ascending';
    }
    return 'descending';
  }
  return 'none';
}

function renderHeader(header, sort, onClick) {
  const isSortable = header.sortable ?? true;
  const handleClick = isSortable ? () => onClick(header.key) : null;
  const headerClasses = classNames({
    [styles.highlighted]: header.highlighted,
  });
  const buttonClasses = classNames(
    styles.headerButton,
    {
      [styles.clickable]: isSortable,
    },
  );
  const sortLabel = getSortLabel(header, sort);

  let element;
  if (handleClick) {
    element = (
      <button
        key={header.key}
        className={buttonClasses}
        type="button"
        onClick={handleClick}
      >
        { header.element ?? header.label }
        <Icon name={getHeaderIcon(header.key, sort)} />
      </button>
    );
  } else {
    element = (
      <div
        key={header.key}
        className={buttonClasses}
      >
        { header.element ?? header.label }
        <Icon name={getHeaderIcon(header.key, sort)} />
      </div>
    );
  }

  return {
    className: headerClasses,
    value: element,
    sortLabel,
  };
}

function renderCell(item, key, maxWidthSize, isHighlighted) {
  const content = getItemContent(item, key);
  // Sanitize the ID for use as a class
  const sanitizedItemId = sanitizeString(item.id.value.toString());
  const cellClasses = classNames(
    `itemsTableRow-${sanitizedItemId}`,
    `itemsTableColumn-${key}`,
    {
      [styles.highlighted]: isHighlighted,
    },
  );

  switch (typeof content) {
    case 'string':
    case 'number':
      return {
        className: cellClasses,
        value: (
          <div aria-label={content}>
            <MaxWidthText
              className={styles.cell}
              size={maxWidthSize}
              text={content}
            />
          </div>
        ),
      };
    default:
      return {
        className: cellClasses,
        value: content,
      };
  }
}

function renderRow(item, keys, maxWidthSize, highlightedIndex) {
  return {
    key: `${item.id.value}`,
    cells: keys.map((key, index) => renderCell(
      item, key, maxWidthSize, index === highlightedIndex,
    )),
  };
}

function renderPagination(total, currentPage, perPage, onPageChange) {
  if (total <= perPage) return null;

  return (
    <div className={styles.pagination}>
      <Pagination
        currentPage={currentPage}
        perPage={perPage}
        total={total}
        onPageChange={onPageChange}
      />
    </div>
  );
}

function ItemsTable({
  className,
  emptyTableContent,
  headers,
  items,
  onPageChange,
  onSortChange,
  page,
  perPage,
  sort,
  maxWidthSize,
}) {
  const onHeaderClick = (key) => {
    onPageChange?.(1);

    // @note: the parent component is responsible for actually sorting the items
    if (key === sort?.key) {
      onSortChange?.({
        key,
        asc: !(sort?.asc),
      });
    } else {
      const asc = true;
      onSortChange?.({
        key,
        asc,
      });
    }
  };

  const itemsPerPage = onPageChange ? (perPage ?? 25) : 1000000;
  const start = (page - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  const pageItems = items.slice(start, end);
  const headerLabels = headers.map((header) => renderHeader(header, sort, onHeaderClick));
  const keys = headers.map((header) => header.key);
  const highlightedIndex = headers.findIndex((header) => header.highlighted);
  const rows = pageItems.map((item) => renderRow(item, keys, maxWidthSize, highlightedIndex));

  if (items.length === 0) return emptyTableContent;

  return (
    <div className={styles.itemsTable}>
      <Table
        className={className}
        headers={headerLabels}
        rows={rows}
      />
      { renderPagination(items.length, page, itemsPerPage, onPageChange) }
    </div>
  );
}

ItemsTable.propTypes = propTypes;
ItemsTable.defaultProps = defaultProps;

export default ItemsTable;
