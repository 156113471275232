import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Dropdown,
  MultipleDropdown,
  toggleObject,
  useLocalStorage,
} from '@makeably/creativex-design-system';
import {
  getBins,
  getBinItems,
} from 'components/internal/connections/shared';
import {
  NULL_VALUE,
  emptyState,
  filterProps,
  optionArrayProps,
} from 'components/internal/shared';
import FilterTags from 'components/molecules/FilterTags';
import ItemsFilter from 'components/molecules/ItemsFilter';
import ItemsTable from 'components/molecules/ItemsTable';
import {
  findObjectByValue,
  findObjectsByValue,
} from 'utilities/array';
import { saveItemsCsvFile } from 'utilities/file';
import { saveFilterQuery } from 'utilities/filtering';
import { getItemSortBy } from 'utilities/item';
import {
  filterItems,
  getValueOptions,
} from 'utilities/itemFilter';
import {
  sanitizeString,
  toDate,
  toSpend,
} from 'utilities/string';
import {
  getPage,
  getParams,
  redirectWithParam,
  setParam,
} from 'utilities/url';
import styles from './AdAccountsMetrics.module.css';

const recordProps = PropTypes.shape({
  adAccountApiId: PropTypes.string.isRequired,
  adAccountCreatedAt: PropTypes.string.isRequired,
  adAccountId: PropTypes.number.isRequired,
  brand: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
  consumedSpend: PropTypes.number.isRequired,
  disabledBrandPageSpend: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  importedSpend: PropTypes.number.isRequired,
  inProgressSpend: PropTypes.number.isRequired,
  market: PropTypes.string.isRequired,
  missingBrandPageSpend: PropTypes.number.isRequired,
  missingDisplaySpend: PropTypes.number.isRequired,
  missingMbmmSpend: PropTypes.number.isRequired,
  pendingReviewSpend: PropTypes.number.isRequired,
  reviewedSpend: PropTypes.number.isRequired,
  staleSpend: PropTypes.number.isRequired,
  unreviewableSpend: PropTypes.number.isRequired,
  unsupportedSpend: PropTypes.number.isRequired,
});

const propTypes = {
  companyOptions: optionArrayProps.isRequired,
  endDate: PropTypes.string.isRequired,
  maxDate: PropTypes.string.isRequired,
  minDate: PropTypes.string.isRequired,
  records: PropTypes.arrayOf(recordProps).isRequired,
  startDate: PropTypes.string.isRequired,
  initialFilterSelections: filterProps,
  initialMetrics: PropTypes.arrayOf(PropTypes.string),
  initialSegments: PropTypes.arrayOf(PropTypes.string),
  selectedCompanyId: PropTypes.number,
};

const defaultProps = {
  initialFilterSelections: {},
  initialMetrics: undefined,
  initialSegments: undefined,
  selectedCompanyId: undefined,
};

const segments = [
  {
    isDefault: true,
    label: 'Channel',
    value: 'channel',
  },
  {
    label: 'Market',
    value: 'market',
  },
  {
    label: 'Brand',
    value: 'brand',
  },
  {
    label: 'Ad Account ID',
    value: 'adAccountApiId',
  },
];

const metrics = [
  {
    label: 'Date Linked',
    value: 'linked',
  },
  {
    isDefault: true,
    label: 'Topline',
    value: 'top',
  },
  {
    isDefault: true,
    label: 'Topline Coverage',
    value: 'topCov',
  },
  {
    isDefault: true,
    label: 'Reviewable',
    value: 'reviewable',
  },
  {
    isDefault: true,
    label: 'Reviewed',
    value: 'reviewed',
  },
  {
    isDefault: true,
    label: 'Pending Review',
    value: 'pending',
  },
  {
    isDefault: true,
    label: 'Reviewable Coverage',
    value: 'reviewableCov',
  },
  {
    isDefault: true,
    label: 'Unreviewable',
    value: 'unreviewable',
  },
  {
    isDefault: true,
    label: 'Unsupported',
    value: 'unsupported',
  },
  {
    label: 'Missing Brand Page',
    value: 'missBp',
  },
  {
    label: 'Missing Display',
    value: 'display',
  },
  {
    label: 'Missing MBMM',
    value: 'mbmm',
  },
  {
    label: 'Actionable Rate',
    value: 'actionableRate',
  },
  {
    label: 'In Progress',
    value: 'progress',
  },
  {
    label: 'Disabled Brand Page',
    value: 'disBp',
  },
  {
    label: 'Stale',
    value: 'stale',
  },
];

const filterDimensions = [
  {
    value: 'channel',
    label: 'Channel',
  },
  {
    value: 'market',
    label: 'Market',
  },
  {
    value: 'brand',
    label: 'Brand',
  },
  {
    value: 'adAccountApiId',
    label: 'Ad Account ID',
  },
  {
    value: 'linked',
    label: 'Date Linked',
  },
];

function optionToHeader({
  label,
  value,
}) {
  return {
    key: value,
    label,
  };
}

function getInitial(options, initialValues) {
  if (initialValues) {
    return findObjectsByValue(options, initialValues);
  }
  return options.filter(({ isDefault }) => isDefault);
}

function getItems(records) {
  return records.map(({
    adAccountApiId,
    adAccountCreatedAt,
    brand,
    channel,
    consumedSpend,
    disabledBrandPageSpend,
    id,
    importedSpend,
    inProgressSpend,
    market,
    missingBrandPageSpend,
    missingDisplaySpend,
    missingMbmmSpend,
    pendingReviewSpend,
    reviewedSpend,
    staleSpend,
    unreviewableSpend,
    unsupportedSpend,
  }) => ({
    adAccountApiId: { value: adAccountApiId },
    brand: { value: brand },
    channel: { value: channel },
    disBp: {
      label: toSpend(disabledBrandPageSpend),
      value: disabledBrandPageSpend,
    },
    display: {
      label: toSpend(missingDisplaySpend),
      value: missingDisplaySpend,
    },
    id: { value: id },
    linked: {
      label: toDate(new Date(adAccountCreatedAt), true),
      value: adAccountCreatedAt,
    },
    market: { value: market },
    mbmm: {
      label: toSpend(missingMbmmSpend),
      value: missingMbmmSpend,
    },
    missBp: {
      label: toSpend(missingBrandPageSpend),
      value: missingBrandPageSpend,
    },
    pending: {
      label: toSpend(pendingReviewSpend),
      value: pendingReviewSpend,
    },
    progress: {
      label: toSpend(inProgressSpend),
      value: inProgressSpend,
    },
    reviewable: {
      label: toSpend(importedSpend),
      value: importedSpend,
    },
    reviewed: {
      label: toSpend(reviewedSpend),
      value: reviewedSpend,
    },
    stale: {
      label: toSpend(staleSpend),
      value: staleSpend,
    },
    top: {
      label: toSpend(consumedSpend),
      value: consumedSpend,
    },
    unreviewable: {
      label: toSpend(unreviewableSpend),
      value: unreviewableSpend,
    },
    unsupported: {
      label: toSpend(unsupportedSpend),
      value: unsupportedSpend,
    },
  }));
}

function getCellClass(columnClass, item) {
  if (!item) return '';

  const rowId = sanitizeString(item.id.value.toString());
  const rowClass = `itemsTableRow-${rowId}`;

  return `.${rowClass}.${columnClass}`;
}

function findMatchingColumnCells(items, columnKey) {
  const columnClass = `itemsTableColumn-${columnKey}`;

  return items.reduce(([cells, nexts], item, index) => {
    const value = item[columnKey]?.value;
    const nextItem = items[index + 1];
    const nextValue = nextItem?.[columnKey]?.value;

    if (value === nextValue) {
      const cell = getCellClass(columnClass, item);
      const next = getCellClass(columnClass, nextItem);

      return [[...cells, cell], [...nexts, next]];
    }

    return [cells, nexts];
  }, [[], []]);
}

function findMatchingCells(items, columns) {
  return columns.reduce(([cells, nexts], column) => {
    const columnKey = column.value;
    const [columnCells, columnNexts] = findMatchingColumnCells(items, columnKey);

    return [[...cells, ...columnCells], [...nexts, ...columnNexts]];
  }, [[], []]);
}

function setSegmentStyles(binItems, selectedSegments) {
  const sheet = new CSSStyleSheet();
  const lastSegmentValue = selectedSegments.at(-1)?.value ?? NULL_VALUE;
  const rightColumnId = `table .itemsTableColumn-${lastSegmentValue}`;
  const [cells, nexts] = findMatchingCells(binItems, selectedSegments);

  sheet.replaceSync(`
    ${rightColumnId} { border-right: 1px solid var(--grey-200); }
    ${cells.join(',')} { border-bottom-color: transparent; }
    ${nexts.join(',')} { color: transparent; }
  `);
  document.adoptedStyleSheets = [sheet];
}

function AdAccountsMetrics({
  companyOptions,
  endDate,
  initialFilterSelections,
  initialMetrics,
  initialSegments,
  maxDate,
  records,
  minDate,
  selectedCompanyId,
  startDate,
}) {
  const params = getParams(window);
  const [lastCompanyId, setLastCompanyId] = useLocalStorage('cxIntCompanyId', null);
  const [selectedCompany, setSelectedCompany] = useState(
    findObjectByValue(companyOptions, selectedCompanyId),
  );
  const [items, setItems] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [filterSelections, setFilterSelections] = useState(initialFilterSelections);
  const [selectedSegments, setSelectedSegments] = useState(getInitial(segments, initialSegments));
  const [selectedMetrics, setSelectedMetrics] = useState(getInitial(metrics, initialMetrics));
  const [sort, setSort] = useState();
  const [page, setPage] = useState(getPage(params));

  const headers = useMemo(() => {
    if (selectedSegments.length === 0) {
      return [
        {
          key: NULL_VALUE,
          label: 'No Segments Selected',
        },
        ...selectedMetrics.map(optionToHeader),
      ];
    }
    return [
      ...selectedSegments.map(optionToHeader),
      ...selectedMetrics.map(optionToHeader),
    ];
  }, [selectedSegments, selectedMetrics]);

  const handleCompanyChange = (option) => {
    setSelectedCompany(option);
    setLastCompanyId(option?.value);
    redirectWithParam('company_id', option.value, params, window);
  };

  useEffect(() => {
    if (!selectedCompanyId && lastCompanyId) {
      const found = findObjectByValue(companyOptions, lastCompanyId);

      if (found) handleCompanyChange(found);
    }
  }, [selectedCompanyId, companyOptions, lastCompanyId]);

  useEffect(() => {
    const allItems = getItems(records);
    const options = getValueOptions(filterDimensions, allItems);

    setItems(allItems);
    setFilterOptions(options);
  }, [records]);

  const filteredItems = useMemo(() => (
    filterItems(items, filterSelections)
  ), [items, filterSelections]);

  const binItems = useMemo(() => {
    const bins = getBins(filteredItems, selectedSegments);
    return getBinItems(bins, selectedSegments);
  }, [filteredItems, selectedSegments]);

  const sortedBins = useMemo(() => {
    if (!sort) return binItems;

    const byKeyDir = getItemSortBy(sort.key, sort.asc);
    const total = binItems[0];
    const sorted = binItems.slice(1).sort(byKeyDir);

    return [total, ...sorted];
  }, [binItems, sort]);

  useEffect(() => {
    setSegmentStyles(sortedBins, selectedSegments);
  }, [sortedBins, selectedSegments]);

  const handleEndDateChange = (value) => {
    redirectWithParam('end_date', value, params, window);
  };

  const handleStartDateChange = (value) => {
    redirectWithParam('start_date', value, params, window);
  };

  const handleFilterSelect = async (value) => {
    setPage(1);
    setFilterSelections(value);

    if (Object.keys(value).length > 0) {
      const uuid = await saveFilterQuery(value);
      setParam('filter_uuid', uuid, params, window);
    } else {
      setParam('filter_uuid', '', params, window);
    }
  };

  const handleSegmentsChange = (option) => {
    setSelectedSegments((last) => {
      const updated = toggleObject(last, option);
      const values = updated.map(({ value }) => value);
      setParam('segments', values, params, window);
      return updated;
    });
  };

  const handleMetricsChange = (option) => {
    setSelectedMetrics((last) => {
      const updated = toggleObject(last, option);
      const values = updated.map(({ value }) => value);
      setParam('metrics', values, params, window);
      return updated;
    });
  };

  return (
    <Card className="u-flexColumn u-gap-24">
      <div className="u-flexRow u-justifyBetween u-alignEnd u-gap-16">
        <Dropdown
          label="Company"
          menuProps={{ size: 'medium' }}
          options={companyOptions}
          selected={selectedCompany}
          size="medium"
          onChange={handleCompanyChange}
        />
        <DatePicker
          endDate={endDate}
          maxDate={maxDate}
          minDate={minDate}
          startDate={startDate}
          onEndDateChange={handleEndDateChange}
          onStartDateChange={handleStartDateChange}
        />
      </div>
      <Divider />
      <div className="u-flexRow u-alignCenter u-gap-8">
        <ItemsFilter
          dimensions={filterDimensions}
          isOpen={filterOpen}
          options={filterOptions}
          selections={filterSelections}
          onClose={() => setFilterOpen(false)}
          onOpen={() => setFilterOpen(true)}
          onSelect={handleFilterSelect}
        />
        <FilterTags
          dimensions={filterDimensions}
          selections={filterSelections}
          onClick={() => setFilterOpen(true)}
          onRemove={handleFilterSelect}
        />
      </div>
      <div className="u-flexRow u-justifyBetween u-alignEnd u-gap-16">
        <div className="u-flexRow u-alignEnd u-gap-16">
          <MultipleDropdown
            label="Segments"
            options={segments}
            selected={selectedSegments}
            onChange={handleSegmentsChange}
          />
          <MultipleDropdown
            label="Metrics"
            options={metrics}
            selected={selectedMetrics}
            onChange={handleMetricsChange}
          />
        </div>
        <Button
          label="Download CSV"
          variant="secondary"
          onClick={() => saveItemsCsvFile('CoverageDashboard', sortedBins, headers)}
        />
      </div>
      <ItemsTable
        className={`u-scrollShadowRight ${styles.table}`}
        emptyTableContent={emptyState}
        headers={headers}
        items={sortedBins}
        page={page}
        sort={sort}
        onPageChange={setPage}
        onSortChange={setSort}
      />
    </Card>
  );
}

AdAccountsMetrics.propTypes = propTypes;
AdAccountsMetrics.defaultProps = defaultProps;

export default AdAccountsMetrics;
